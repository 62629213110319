import { Component, Input } from '@angular/core';
import { Feature } from 'src/app/utils/feature';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
})
export class FeaturesComponent {
  @Input() readonly features: Feature[];

  readonly uniqid = Math.random().toString(36).substr(2);

  constructor(private readonly domSanitizer: DomSanitizer) { }

  getSafeTitle(feature: Feature): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(feature.title.replace(/\n/g, '<br>'));
  }
}
