import { from, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ClassType, transformAndValidate } from 'class-transformer-validator';

export function transformAndValidatePipe<T extends object, R = T>(model: ClassType<T>): MonoTypeOperatorFunction<R>;
export function transformAndValidatePipe<T extends object, R = T[]>(model: ClassType<T>): MonoTypeOperatorFunction<R>;
export function transformAndValidatePipe<T extends object, R = T | T[]>(model: ClassType<T>): MonoTypeOperatorFunction<R> {
  return (obs: Observable<R>) => {
    return obs.pipe(
      switchMap((results: R) => {
        return from(transformAndValidate(model, results as any) as unknown as Promise<R>);
      }),
    );
  };
}
