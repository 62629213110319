import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from 'src/app/shared/components/card/card.component';
import { RouterLink } from '@angular/router';
import { SpinnerComponent } from 'src/app/shared/components/spinner/spinner.component';

@NgModule({
  declarations: [
    CardComponent,
    SpinnerComponent,
  ],
  exports: [
    CardComponent,
    SpinnerComponent,
  ],
  imports: [
    CommonModule,
    RouterLink,
  ],
})
export class SharedModule { }
