<div class="section bg-primary get-access" id="get-access">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-7 py-lg-5">
        <div class="display-3 mb-3 d-lg-none" data-aos="fade-right" data-aos-once="true" data-aos-anchor="#get-access">
          <T str="Work smarter.<br>Join LiveLink." key="join_livelink.title" [sanitize]="true"></T>
        </div>
        <div class="display-3 mb-4 d-none d-lg-block" data-aos="fade-right" data-aos-once="true" data-aos-anchor="#get-access">
          <T str="Work smarter. Join LiveLink." key="join_livelink.title_large" [sanitize]="true"></T>
        </div>
        <p class="lead" data-aos="fade-right" data-aos-once="true" data-aos-anchor="#get-access" data-aos-delay="100">
          <T str="Gain complete control over your entire fleet. Register now." key="join_livelink.subtitle"></T>
        </p>
        <a class="btn btn-alt btn-white mt-3" [href]="onboardingUrl">
          <T str="Get Started" key="join_livelink.get_started"></T>
        </a>

        <img src="assets/images/livelink-app-mockup.png" class="d-lg-none mt-5" alt="" data-aos="fade-up" data-aos-once="true">
      </div>
    </div>
  </div>
</div>
