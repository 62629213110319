import { Type } from 'class-transformer';
import { IsDateString, IsDefined, IsOptional, IsString } from 'class-validator';
import { DataMember } from 'src/app/utils/data-member';

export class LatestUpdatePostDto {
  @DataMember('title', IsString())
  title: string;

  @DataMember('url', IsOptional(), IsString())
  url: string;

  @DataMember('meta_title', IsString(), IsOptional())
  metaTitle: string;

  @DataMember('meta_description', IsString(), IsOptional())
  metaDescription: string;

  @DataMember('tagline', IsString(), IsOptional())
  tagline: string;

  @DataMember('tagline_highlight', IsString(), IsOptional())
  taglineHighlight: string;

  @DataMember('body', IsDefined())
  body: any;

  @DataMember('release_date', IsString(), IsOptional())
  releaseDate: string;

  @DataMember('main_image', IsDefined())
  mainImage: any;

  @DataMember('status', IsString(), IsOptional())
  status: boolean;

  @DataMember('related_articles', IsOptional())
  relatedArticles: [string];
}

export class LatestUpdatePostResponseDto {
  @Type(() => LatestUpdatePostDto)
  readonly data: LatestUpdatePostDto[];

  readonly total: number;
}
