import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-join-livelink',
  templateUrl: './join-livelink.component.html',
  styleUrls: ['./join-livelink.component.scss']
})
export class JoinLivelinkComponent {
  readonly onboardingUrl = environment.onboardingUrl;
}
