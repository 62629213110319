<div class="bg-dark text-muted">
  <div class="container">
    <div class="py-3 d-flex justify-content-between flex-wrap">
      <div class="d-lg-flex align-items-center flex-wrap mb-3 mb-lg-0 mr-lg-3">
        <div class="my-2">
          <a href="https://www.jcb.com/en-gb/about" target="_blank" class="link no-underline text-muted mr-lg-5">
            <T str="About Us" key="footer.about_us"></T>
          </a>
        </div>
        <div class="my-2">
          <a href="https://www.jcb.com/en-gb/about/sustainable-solutions" target="_blank" class="link no-underline text-muted mr-lg-5">
            <T str="Corporate Responsibility" key="footer.corporate_responsibility"></T>
          </a>
        </div>
        <div class="my-2">
          <a href="https://www.jcb.com/en-gb/info/legal-notices" target="_blank" class="link no-underline text-muted mr-lg-5">
            <T str="Legal Notice" key="footer.legal_notice"></T>
          </a>
        </div>
        <div class="my-2">
          <a href="https://www.jcb.com/en-gb/info/privacy-policy" target="_blank" class="link no-underline text-muted mr-lg-5">
            <T str="Cookies and Privacy Policy" key="footer.cookies_and_privacy_policy"></T>
          </a>
        </div>
      </div>
      <div class="my-2 text-right">
        &copy; {{currentYear}} J C Bamford Excavators Ltd.
      </div>
    </div>
  </div>
</div>
