type Validator = (target: any, key: string) => void;

export function DataMember<T = any>(serializeTo: string, ...validators: Validator[]) {
  return function (target: any, key: string) {
    if (key !== serializeTo) {
      Object.defineProperty(target, key, {
        set(value: any) {
          this[serializeTo] = value;
        },
        get() {
          return this[serializeTo];
        },
      });
    }

    if (validators && validators.length) {
      validators.forEach(validator => validator(target, serializeTo));
    }
  };
}
