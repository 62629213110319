import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Feature } from 'src/app/utils/feature';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit, OnDestroy {
  private readonly timeInMillisecondsPerSlide = 10000;
  private timeout: any;

  @Input() readonly features: Feature[];
  @Input() readonly accent = false;

  currentIndex = 0;
  currentPercentage = 0;

  constructor(
    private readonly domSanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.setSlideTo(0);
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeout);
  }

  setSlideTo(num: number): void {
    clearTimeout(this.timeout);

    this.currentIndex = num;
    this.currentPercentage = 0;

    this.timeout = setTimeout(() => {
      this.setSlideTo((this.currentIndex + 1) % this.features.length);
    }, this.timeInMillisecondsPerSlide);
  }

  getSafeTitle(feature: Feature): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(feature.title.replace(/\n/g, '<br>'));
  }

  getCurrentFeature(): Feature {
    return this.features[this.currentIndex];
  }
}
