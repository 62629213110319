import { Component, Input, OnInit } from '@angular/core';

enum SlideshowAnimationDirection {
  None,
  Forwards,
  Backwards,
}

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss'],
})
export class SlideshowComponent implements OnInit {
  private timeout: number;

  @Input() readonly slides: string[];
  @Input() readonly height: number;

  readonly SlideshowAnimationDirection = SlideshowAnimationDirection;

  currentSlide: string;
  currentSlideIndex: number;
  previousSlide: string;
  previousSlideIndex: number;
  animationDirection: SlideshowAnimationDirection;

  ngOnInit(): void {
    this.slideTo(0);
    this.initSlideshow();
  }

  initSlideshow(): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.slideTo((this.currentSlideIndex + 1) % this.slides.length);
      this.initSlideshow();
    }, 5000);
  }

  slideTo(ind: number): void {
    this.previousSlide = this.currentSlide;
    this.previousSlideIndex = this.currentSlideIndex;

    this.currentSlideIndex = ind;
    this.currentSlide = this.slides[ind];

    if (this.previousSlide) {
      if (this.previousSlideIndex < this.currentSlideIndex) {
        this.animationDirection = SlideshowAnimationDirection.Forwards;
      } else {
        this.animationDirection = SlideshowAnimationDirection.Backwards;
      }

      setTimeout(() => {
        this.animationDirection = SlideshowAnimationDirection.None;
      }, 800);
    } else {
      this.animationDirection = SlideshowAnimationDirection.None;
    }
  }

  swipe(type: number): void {
    console.log(type);
  }
}
