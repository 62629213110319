import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { environment } from 'src/environments/environment';
import { T } from '@transifex/angular';

export class Testimony {
  name: string;
  companyName: string;
  position: string;
  quote: string;
  imageUrl: string;
}

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
})
export class TestimonialsComponent implements OnInit, OnDestroy {
  @T('Mick Ledden', { _key: 'testimonials.testimony_1.name' })
  private readonly testimony1Name!: string;
  @T('Sunbelt Rentals UK', { _key: 'testimonials.testimony_1.company_name' })
  private readonly testimony1CompanyName!: string;
  @T('Director of Operational Excellence', { _key: 'testimonials.testimony_1.position' })
  private readonly testimony1Position!: string;
  @T('Despite challenging Covid-19 restrictions we have worked closely with the JCB team to develop an integrated telematics reporting platform for our customers. This offers a new suite of telematics reports that are accessible 24/7 to all Sunbelt Rentals customers via our new Command Centre application. Over the next few months we will work with our customers and strategic partners to introduce, review and enhance the platform.', { _key: 'testimonials.testimony_1.quote' })
  private readonly testimony1Quote!: string;

  @T('David Funnell', { _key: 'testimonials.testimony_2.name' })
  private readonly testimony2Name!: string;
  @T('Powell Forestry', { _key: 'testimonials.testimony_2.company_name' })
  private readonly testimony2CompanyName!: string;
  @T('Harvesting manager', { _key: 'testimonials.testimony_2.position' })
  private readonly testimony2Position!: string;
  @T('Using JCB LiveLink with all its features has enabled us to have a better understanding of the machines and their operation.  The communication with the operator has improved which has improved the management of the operation, health and safety and maintenance of the machine.  We are actively promoting the App to our customers to provide them the confidence that we are a professional partner to work alongside.', { _key: 'testimonials.testimony_2.quote' })
  private readonly testimony2Quote!: string;

  private readonly timeInMillisecondsPerTestimony = 10000;
  protected readonly testimonialsEnabled = environment.testimonialsEnabled;

  private timeout: any;

  readonly testimonials: Testimony[] = [{
    name: this.testimony1Name,
    companyName: this.testimony1CompanyName,
    position: this.testimony1Position,
    quote: this.testimony1Quote,
    imageUrl: 'assets/images/sunbelt-rentals-uk.svg',
  }, {
    name: this.testimony2Name,
    companyName: this.testimony2CompanyName,
    position: this.testimony2Position,
    quote: this.testimony2Quote,
    imageUrl: 'assets/images/powell-forestry-logo.svg',
  }];

  currentIndex = 0;
  currentTestimony: Testimony;
  @ViewChildren('testimony') testimonies!: QueryList<any>;
  @ViewChild('testimonialsContainer') testimonialsContainer;

  constructor() { }

  ngOnInit(): void {
    this.selectTestimony(0);
    this.initCarousel();
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeout);
  }

  selectTestimony(num: number): void {
    this.currentIndex = num;
    this.currentTestimony = this.testimonials[num];

    let foundTestimony: HTMLDivElement = null;

    if (this.testimonies) {
      foundTestimony = HTMLDivElement = this.testimonies.toArray().find((t) => t.nativeElement.id === `testimony-${num}`);
    }


    if (foundTestimony) {
      const left = foundTestimony.offsetLeft - (this.testimonialsContainer.nativeElement.offsetWidth / 2) + (foundTestimony.offsetWidth / 2);
      this.testimonialsContainer.nativeElement.scrollTo(left, 0);
    }
  }

  initCarousel(): void {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.selectTestimony((this.currentIndex + 1) % this.testimonials.length);
      this.initCarousel();
    }, this.timeInMillisecondsPerTestimony);
  }
}
