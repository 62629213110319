import { Component, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Select, Store } from '@ngxs/store';
import { HeaderState, SetLoginTooltipActiveAction, SetMobileNavigationActiveAction } from 'src/app/states/header.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Select(HeaderState.mobileNavigationActive) readonly isMobileNavigationActive$: Observable<boolean>;
  @Select(HeaderState.loginTooltipActive) readonly isLoginTooltipActive$: Observable<boolean>;

  readonly websiteUrl = environment.websiteUrl;
  readonly onboradingUrl = environment.onboardingUrl;
  readonly liveLinkClassicUrl = environment.liveLinkClassicUrl;

  isScrolled = false;

  constructor(private readonly store: Store) { }

  getIsMobileNavigationActiveSnapshot(): boolean {
    return this.store.selectSnapshot(HeaderState.mobileNavigationActive);
  }

  getIsLoginTooltipActiveSnapshot(): boolean {
    return this.store.selectSnapshot(HeaderState.loginTooltipActive);
  }

  setMobileNavigationActive(active: boolean): void {
    this.store.dispatch(new SetMobileNavigationActiveAction(active));
  }

  setLoginTooltipActive(active: boolean): void {
    this.store.dispatch(new SetLoginTooltipActiveAction(active));
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    const scrollTop = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
    this.isScrolled = scrollTop > 80;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick($event: any): void {
    if (this.getIsMobileNavigationActiveSnapshot()) {
      const { target } = $event as { target: HTMLElement };
      if (!target.classList.contains('mobile-nav-area')) {
        this.setMobileNavigationActive(false);
      }
    }

    if (this.getIsLoginTooltipActiveSnapshot()) {
      const { target } = $event as { target: HTMLElement };
      if (!target.classList.contains('btn-dropdown-area')) {
        this.setLoginTooltipActive(false);
      }
    }
  }
}
