<div class="d-none d-lg-flex align-content-center justify-content-around flex-wrap">
  <div class="m-3" *ngFor="let slide of slides">
    <img [src]="slide">
  </div>
</div>

<div
  class="slideshow-content d-lg-none"
  [class.slideshow-animation-forwards]="animationDirection === SlideshowAnimationDirection.Forwards"
  [class.slideshow-animation-backwards]="animationDirection === SlideshowAnimationDirection.Backwards"
>
  <div class="slideshow-preload">
    <img [src]="slide" *ngFor="let slide of slides">
  </div>

  <div
    class="slideshow-current"
    [style.height]="height + 'px'"
    [style.backgroundImage]="'url(' + currentSlide + ')'"
  ></div>

  <div
    class="slideshow-previous"
    [style.height]="height + 'px'"
    [style.backgroundImage]="'url(' + previousSlide + ')'"
  ></div>

  <div class="slideshow-counter">
    <div
      class="slideshow-dot"
      *ngFor="let slide of slides; let ind = index"
      [class.slideshow-dot--active]="ind === currentSlideIndex"
    ></div>
  </div>
</div>
