<ng-template #card>
  <div class="card">
    <div class="card__top">
      <img [src]="imgSrc" [alt]="imgAlt">
    </div>
    <div class="card__bottom">
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="link && !routerLink">
  <a [href]="link" class="card__link">
    <ng-template [ngTemplateOutlet]="card"></ng-template>
  </a>
</ng-container>

<ng-container *ngIf="routerLink && !link">
  <a [routerLink]="routerLink" class="card__link">
    <ng-template [ngTemplateOutlet]="card"></ng-template>
  </a>
</ng-container>

<ng-container *ngIf="!routerLink && !link">
  <ng-template [ngTemplateOutlet]="card"></ng-template>
</ng-container>
