<div class="container">
  <div class="row py-1 my-3 my-lg-4" *ngFor="let feature of features; let ind = index" [id]="'aos-feature-anchor-' + uniqid + '-' + ind">
    <div
      class="col-12 col-md-8 mb-4 mt-lg-0"
      [class.order-md-2]="ind % 2"
      [class.pl-lg-5]="ind % 2"
      [class.pr-lg-5]="ind % 2 === 0"
    >
      <div
        class="rounded feature-image"
        data-aos-once="true"
        data-aos-offset="200"
        [attr.data-aos]="ind % 2 ? 'fade-left' : 'fade-right'"
        [attr.data-aos-anchor]="'#aos-feature-anchor-' + uniqid + '-' + ind"
        [style.backgroundImage]="feature.imageUrl ? 'url(' + feature.imageUrl + ')' : ''"
      ></div>
    </div>
    <div class="col-12 col-md-4 d-flex align-items-center mb-4" [class.order-md-1]="ind % 2">
      <div
        class="pl-md-5"
        data-aos-once="true"
        data-aos-offset="200"
        [attr.data-aos]="ind % 2 ? 'fade-right' : 'fade-left'"
        [attr.data-aos-anchor]="'#aos-feature-anchor-' + uniqid + '-' + ind"
      >
        <div class="icon mb-3 mb-lg-4" *ngIf="feature.iconUrl" [style.backgroundImage]="'url(' + feature.iconUrl + ')'"></div>
        <h3 class="mb-3 mb-lg-4" [innerHTML]="getSafeTitle(feature)"></h3>
        <p class="feature-description">{{ feature.description }}</p>

        <div class="mt-3 mt-lg-4" *ngIf="feature.linkText && feature.linkUrl">
          <a class="link text-primary" [href]="feature.linkUrl">{{ feature.linkText }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
