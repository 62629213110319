<ng-template #loading>
  <div [style.height.px]="pageHeight || 500" class="no-results-container d-flex align-items-center justify-content-center">
    <app-spinner></app-spinner>
  </div>
</ng-template>

<ng-template #noresults>
  <div class="no-results-container d-flex flex-column align-items-center justify-content-center">
    <img class="mb-4" src="assets/images/no-results-error.svg" alt="No results">
    <h2 class="mb-2">
      <T str="No results found" key="latest_updates.no_results_title"></T>
    </h2>
    <p class="text-muted text-center">
      <T
        str="No results found for your search. Please try again with <br/> different keywords or select a different tab."
        key="latest_updates.no_results_description"
        [sanitize]="true"
      ></T>
    </p>
  </div>
</ng-template>

<div class="hero">
  <h1>
    <T str="Latest Updates" key="latest_updates.title"></T>
  </h1>
</div>

<div class="container container-fluid">
  <div class="d-flex flex-column flex-md-row justify-content-between my-5">
    <div class="text-center text-md-left mb-4 mb-md-0">
      <button (click)="filter$.next(PostFilterOptions.All)" class="filter-tabs__tab" [class.filter-tabs__tab--active]="filter$.value === PostFilterOptions.All">
        <T str="All" key="latest_updates.filter_tabs.all"></T>
      </button>
      <button (click)="filter$.next(PostFilterOptions.Upcoming)" class="filter-tabs__tab" [class.filter-tabs__tab--active]="filter$.value === PostFilterOptions.Upcoming">
        <T str="Upcoming" key="latest_updates.filter_tabs.upcoming"></T>
      </button>
      <button (click)="filter$.next(PostFilterOptions.Released)" class="filter-tabs__tab" [class.filter-tabs__tab--active]="filter$.value === PostFilterOptions.Released">
        <T str="Released" key="latest_updates.filter_tabs.released"></T>
      </button>
    </div>
    <div class="mx-auto mx-md-0">
      <div class="search">
        <img class="search__icon" src="assets/images/search-icon.svg" alt="Search icon">
        <input
          class="search__input"
          type="text"
          placeholder="{{ 'Search' | translate:{ _key: 'common.search' } }}"
          (keyup)="setSearchTerm($event)"
        >
      </div>
    </div>
  </div>
  <div #resultsContainerRef>
    <ng-container *ngIf="posts$ | async as posts; else loading">
      <ng-container *ngIf="!isLoading; else loading">
        <div class="row" *ngIf="posts.length > 0; else noresults">
          <div class="col-12 col-md-6 col-xl-4 mb-5" *ngFor="let post of posts">
            <app-card
              [imgSrc]="post?.mainImage?.fields.file.url || 'assets/images/article-placeholder.png'"
              imgAlt="{{ 'Article image' | translate:{ _key: 'latest_updates.article_image' } }}"
              [routerLink]="['/', 'latest-updates', post.url]"
            >
              <div>
                <p class="text-muted text-small mb-1">{{ post.releaseDate ? post.releaseDate : '&#x200b;' }}</p>
                <h2>{{ post.title }}</h2>
              </div>
            </app-card>
          </div>
        </div>
        <div class="text-center mb-5" *ngIf="posts.length < total">
          <button class="btn btn-primary" (click)="loadMorePosts()">
            <T str="LOAD MORE" key="common.load_more"></T>
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<app-testimonials></app-testimonials>
<app-join-livelink></app-join-livelink>
