import { Injectable } from '@angular/core';
import { ContentfulService } from 'src/app/contentful/contentful.service';
import { ContentfulQueryParamsDto } from 'src/app/contentful/dto/contentful-query-params.dto';
import { map, Observable } from 'rxjs';
import { LatestUpdatePostResponseDto } from 'src/app/contentful/dto/latest-update-post.dto';
import { transformAndValidatePipe } from 'src/app/utils/operators';
import { LatestUpdateQueryParamsDto } from 'src/app/contentful/dto/latest-update-query-params.dto';

export enum PostFilterOptions {
  All = 'all',
  Upcoming = 'upcoming',
  Released = 'released',
}

export interface LatestUpdatePostOptions {
  filter?: PostFilterOptions;
  searchTerm?: string;
}

@Injectable({
  providedIn: 'root',
})
export class LatestUpdatesListPageService {
  constructor(
    private readonly contentfulService: ContentfulService,
  ) { }

  getPosts(params?: LatestUpdateQueryParamsDto, options?: LatestUpdatePostOptions): Observable<LatestUpdatePostResponseDto> {
    const queryParams = this.buildQueryParams(params, options);
    return this.contentfulService.query(queryParams).pipe(
      map((response) => ({
        data: response.items.map((item) => item.fields),
        total: response.total,
      }) as LatestUpdatePostResponseDto),
      transformAndValidatePipe(LatestUpdatePostResponseDto),
    );
  }

  buildQueryParams(params?: LatestUpdateQueryParamsDto, options?: LatestUpdatePostOptions): ContentfulQueryParamsDto {
    const defaultDto = {
      limit: params.limit,
      skip: params.skip,
      order: params.order,
      contentType: 'latestUpdatePost',
      select: params.select,
      fieldsReleaseDateExists: null,
      fieldsSearchTerm: null,
      fieldsStatus: null,
    };

    if (options?.filter === 'upcoming') {
      defaultDto.fieldsStatus = false;
    }

    if (options?.filter === 'released') {
      defaultDto.fieldsStatus = true;
    }

    if(options.searchTerm) {
      defaultDto.fieldsSearchTerm = options.searchTerm;
    }

    return new ContentfulQueryParamsDto(defaultDto);
  }
}
