import { Injectable } from '@angular/core';
import { createClient, EntryCollection } from 'contentful';
import { environment } from 'src/environments/environment';
import { from, Observable } from 'rxjs';
import { ContentfulQueryParamsDto } from 'src/app/contentful/dto/contentful-query-params.dto';

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  private readonly client = createClient({
    space: environment.contentfulSpaceId,
    environment: environment.contentfulEnvironment,
    accessToken: environment.contentfulAccessToken,
  });

  query<T>(params: ContentfulQueryParamsDto): Observable<EntryCollection<T>> {
    return from(this.client.getEntries<T>(params));
  }

  constructor() {}
}
