import { IsBoolean, IsNumber, IsOptional, IsString } from 'class-validator';
import { DataMember } from 'src/app/utils/data-member';

export class ContentfulQueryParamsDto {
  @DataMember('skip', IsNumber())
  skip: number = 0;

  @DataMember('limit', IsOptional())
  limit: number = 10;

  @DataMember('order', IsOptional(), IsString())
  order?: string;

  @DataMember('content_type', IsOptional(), IsString())
  contentType?: string;

  @DataMember('select', IsOptional(), IsString())
  select: string;

  @DataMember('fields.url', IsOptional(), IsString())
  fieldsUrl?: string;

  @DataMember('fields.title[match]', IsOptional(), IsString())
  fieldsSearchTerm?: string;

  @DataMember('fields.status', IsOptional(), IsBoolean())
  fieldsStatus?: boolean;

  @DataMember('fields.url[in]', IsOptional())
  fieldsUrlIn?: string;

  constructor(dto?: ContentfulQueryParamsDto) {
    if (dto) {
      this.skip = dto.skip;
      this.limit = dto.limit;
      this.order = dto.order;
      this.contentType = dto.contentType;
      this.select = dto.select;
      this.fieldsUrl = dto.fieldsUrl;
      this.fieldsSearchTerm = dto.fieldsSearchTerm;
      this.fieldsStatus = dto.fieldsStatus;
      this.fieldsUrlIn = dto.fieldsUrlIn;
    }
  }
}
