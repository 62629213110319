<div *ngIf="testimonialsEnabled" class="section bg-dark text-white py-5" id="testimonials">
  <div class="py-3 py-lg-5">
    <div class="container text-center py-3">
      <h1 class="text-primary">
        <T str="Testimonials" key="testimonials.title"></T>
      </h1>

      <div class="narrow mx-auto mt-5" data-aos="fade-up" id="aos-testimonials-anchor" data-aos-once="true">
        <div class="text-large">
          <em>"{{ currentTestimony.quote }}"</em>
        </div>
      </div>

      <div class="d-none d-lg-block" data-aos="fade-up" data-aos-anchor="#aos-testimonials-anchor" data-aos-once="true" data-aos-delay="100">
        <div class="d-flex align-content-center justify-content-center flex-wrap mt-4 mx-auto">
          <div
            #testimony
            class="text-center m-4 mx-md-5 fade-animated cursor-pointer testimony"
            *ngFor="let testimony of testimonials; let ind = index"
            [id]="'testimony-' + ind"
            [class.faded]="currentTestimony !== testimony"
            (click)="selectTestimony(ind)"
          >
            <div
              class="block mx-auto mb-3"
            >
              <img [src]="testimony.imageUrl" [alt]="testimony.companyName" />
            </div>
            <strong class="text-uppercase">{{ testimony.name }}</strong>
            <div>{{ testimony.position }}</div>
          </div>
        </div>
      </div>

      <div #testimonialsContainer class="d-lg-none" data-aos="fade-up" data-aos-anchor="#aos-testimonials-anchor" data-aos-once="true" data-aos-delay="100">
        <div class="d-flex align-content-center justify-content-center flex-wrap mt-4 mx-auto" [style.width]="testimonials.length * 150 + 'px'">
          <div
            class="text-center m-4 mx-md-5 fade-animated cursor-pointer testimony"
            *ngFor="let testimony of testimonials; let ind = index"
            [id]="'testimony-' + ind"
            [class.faded]="currentTestimony !== testimony"
            (click)="selectTestimony(ind)"
          >
            <div
              class="block mx-auto mb-3"
            >
              <img [src]="testimony.imageUrl" [alt]="testimony.companyName" />
            </div>
            <strong class="text-uppercase">{{ testimony.name }}</strong>
            <div>{{ testimony.position }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
