<div class="carousel" [class.accent]="accent">
  <div class="container" *ngIf="getCurrentFeature() as currentFeature">
    <div class="bg-white rounded carousel-image-container" data-aos="fade-up" data-aos-once="true">
      <img
        class="carousel-image"
        [src]="currentFeature.imageUrl ? currentFeature.imageUrl : ''"
      >
    </div>
  </div>

  <div class="accent-container">
    <div class="container py-5">
      <div class="row">
        <div
          class="col-12 col-lg-4 fade-animated cursor-pointer"
          *ngFor="let feature of features; let ind = index"
          data-aos="fade-right"
          data-aos-once="true"
          [attr.data-aos-delay]="ind * 100"
          [class.faded]="ind !== currentIndex"
          (click)="setSlideTo(ind)"
        >
          <div class="progress mb-5">
            <div class="progress-bar"></div>
          </div>

          <div class="icon mb-4" *ngIf="feature.iconUrl" [style.backgroundImage]="'url(' + feature.iconUrl + ')'"></div>
          <h2 class="mb-3" [innerHTML]="getSafeTitle(feature)"></h2>
          <p class="pr-5">{{ feature.description }}</p>
        </div>
      </div>

      <div class="d-flex d-lg-none align-items-start justify-content-center mt-4">
        <div
          class="btn m-2 rounded-circle p-2 px-3"
          *ngFor="let f of features; let ind = index"
          [class.btn-black]="ind === currentIndex"
          [class.btn-light]="ind !== currentIndex"
          (click)="setSlideTo(ind)"
        >
          {{ ind + 1 }}
        </div>
      </div>
    </div>
  </div>
</div>
