import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

export class SetMobileNavigationActiveAction {
  static readonly type = '[HeaderState] Set mobile navigation active action';

  constructor(readonly mobileNavigationActive: boolean) { }
}

export class SetLoginTooltipActiveAction {
  static readonly type = '[HeaderState] Set login tooltip active action';

  constructor(readonly loginTooltipActive: boolean) { }
}

interface HeaderStateModel {
  mobileNavigationActive: boolean;
  loginTooltipActive: boolean;
}

@State<HeaderStateModel>({
  name: 'header',
  defaults: {
    mobileNavigationActive: false,
    loginTooltipActive: false,
  },
})
@Injectable()
export class HeaderState {
  @Selector()
  static mobileNavigationActive(state: HeaderStateModel): boolean {
    return state.mobileNavigationActive;
  }

  @Selector()
  static loginTooltipActive(state: HeaderStateModel): boolean {
    return state.loginTooltipActive;
  }

  @Action(SetMobileNavigationActiveAction)
  setMobileNavigationActiveAction(ctx: StateContext<HeaderStateModel>, action: SetMobileNavigationActiveAction): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      mobileNavigationActive: action.mobileNavigationActive,
    });
  }

  @Action(SetLoginTooltipActiveAction)
  setLoginTooltipActiveAction(ctx: StateContext<HeaderStateModel>, action: SetLoginTooltipActiveAction): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      loginTooltipActive: action.loginTooltipActive,
    });
  }
}
