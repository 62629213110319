<ng-container *ngIf="post$ | async as post">
  <div class="hero">
    <div class="container">
      <div class="hero__inner">
        <h2 class="hero__tagline" *ngIf="post.tagline || post.taglineHighlight">{{post?.tagline}} <span *ngIf="post.taglineHighlight">{{post.taglineHighlight}}</span></h2>
        <h2 class="hero__tagline" *ngIf="!post.tagline && !post.taglineHighlight">
          <T str="Increase uptime. Reduce admin. <span>Make better decisions.</span>" key="latest_updates.details.default_tagline" [sanitize]="true"></T>
        </h2>
        <img [src]="post.mainImage?.fields.file.url || 'assets/images/article-placeholder.png'" alt="Article hero image">
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12 my-5">
        <div class="post__content">
          <p class="post__sub-title">
            <T str="NOW YOU CAN..." key="latest_updates.details.now_you_can"></T>
          </p>
          <h1 class="post__title">{{post.title}}</h1>
          <p class="post__release-date" *ngIf="post.releaseDate">{{post.releaseDate}}</p>
          <div class="post__body" [innerHTML]="post.body | parseRichtext"></div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="relatedArticles$ | async as relatedArticles">
    <div *ngIf="relatedArticles && relatedArticles.length > 0" class="related-articles">
      <h2 class="related-articles__title">
        <T str="Related articles" key="latest_updates.details.related_articles"></T>
      </h2>

      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-4" *ngFor="let relatedArticle of relatedArticles">
            <app-card
              [imgSrc]="relatedArticle.mainImage.fields.file.url"
              imgAlt="{{ 'Random image' | translate:{ _key: 'latest_updates.details.related_articles_random_image' } }}"
              [routerLink]="['/', 'latest-updates', relatedArticle.url]"
            >
              <div>
                <p class="text-muted text-small mb-1" *ngIf="relatedArticle.releaseDate">{{relatedArticle.releaseDate}}</p>
                <h2>{{relatedArticle.title}}</h2>
              </div>
            </app-card>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <app-testimonials></app-testimonials>
  <app-join-livelink></app-join-livelink>
</ng-container>
