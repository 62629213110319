<header [class.scrolled]="isScrolled">
  <div class="container">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <a href="#" class="mr-lg-4 logo"></a>
        <a class="nav-item" href="#features">
          <T str="Features" key="header.navigation.features"></T>
        </a>
        <a class="nav-item" href="#testimonials">
          <T str="Testimonials" key="header.navigation.testimonials"></T>
        </a>
        <a class="nav-item" [routerLink]="['latest-updates']">
          <T str="Latest Updates" key="header.navigation.latest_updates"></T>
        </a>
        <a class="nav-item" href="#get-access">
          <T str="Get access" key="header.navigation.get_access"></T>
        </a>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <a class="btn text-underline d-none d-lg-block" href="https://jcb.com">JCB.com</a>

        <div class="separator d-none d-xl-block"></div>

        <div class="btn-dropdown d-none d-lg-inline-block">
          <button
            class="btn btn-dropdown-trigger text-underline btn-dropdown-area text-nowrap"
            (click)="setLoginTooltipActive(!getIsLoginTooltipActiveSnapshot())"
          >
            {{ 'Log in' | translate: { _key: 'text.log_in', _tags: 'common' } }}
          </button>

          <div class="btn-dropdown-content text-center btn-dropdown-area" *ngIf="isLoginTooltipActive$ | async">
            <a class="btn btn-primary btn-block btn-dropdown-area" [href]="websiteUrl">
              <T str="Log in to JCB LiveLink" key="header.navigation.log_in_to_jcb_livelink"></T>
            </a>
            <a class="link text-muted mt-3 d-inline-block btn-dropdown-area" [href]="liveLinkClassicUrl">
              <T str="Looking for LiveLink Classic?" key="header.navigation.looking_for_livelink_classic"></T>
            </a>
          </div>
        </div>

        <a class="btn btn-black ml-2" [href]="onboradingUrl">
          <T str="Register" key="header.navigation.register"></T>
        </a>
        <button
          class="menu-button ml-2 mobile-nav-area"
          [class.menu-button--active]="isMobileNavigationActive$ | async"
          (click)="setMobileNavigationActive(!getIsMobileNavigationActiveSnapshot())"
        ></button>
      </div>
    </div>
  </div>
</header>

<div class="mobile-navigation mobile-nav-area" [class.mobile-navigation--active]="isMobileNavigationActive$ | async">
  <div class="mobile-navigation-inner mobile-nav-area">
    <a href="#features"><T str="Features" key="header.navigation.features"></T></a>
    <a href="#testimonials"><T str="Testimonials" key="header.navigation.testimonials"></T></a>
    <a [routerLink]="['latest-updates']"><T str="Latest Updates" key="header.navigation.latest_updates"></T></a>
    <a href="#get-access"><T str="Get access" key="header.navigation.get_access"></T></a>
    <a [href]="websiteUrl" class="text-primary mobile-nav-area">
      <T str="Log in to JCB LiveLink" key="header.navigation.log_in_to_jcb_livelink"></T>
    </a>
    <a [href]="websiteUrl" class="text-primary mobile-nav-area">
      <T str="Register to JCB LiveLink" key="header.navigation.register_to_jcb_livelink"></T>
    </a>

    <a class="link mobile-nav-area" [href]="liveLinkClassicUrl">
      <T str="Looking for LiveLink Classic?" key="header.navigation.looking_for_livelink_classic"></T>
    </a>
  </div>
</div>
