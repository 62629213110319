import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { HeaderState } from 'src/app/states/header.state';
import { Observable } from 'rxjs';
import { TranslationService } from '@transifex/angular';
import { environment } from '../environments/environment';

const languageToLocaleMap = {
  en: 'en_GB',
  'en-GB': 'en_GB',
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @Select(HeaderState.mobileNavigationActive) readonly mobileNavigationActive$: Observable<boolean>;

  protected initialised = false;

  constructor(
    private readonly translationService: TranslationService,
  ) {
    translationService.init({ token: environment.transifexApiToken });
  }

  async ngOnInit(): Promise<void> {
    try {
      const languages = await this.translationService.getLanguages();
      let locale = environment.defaultLanguage;

      for (const language of navigator.languages) {
        let languageLocale = languageToLocaleMap[language];
        if (!languageLocale) {
          // Fallback trying language with underscore
          languageLocale = language.replace('-', '_');
        }

        const isLocaleAvailable = languages.some(x => x.code === languageLocale);
        if (isLocaleAvailable) {
          locale = languageLocale;
          break;
        }
      }

      await this.translationService.setCurrentLocale(locale);
    } catch (err) {
      console.error(err);
    }

    this.initialised = true;
  }
}
