import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Feature } from 'src/app/utils/feature';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { SetLoginTooltipActiveAction } from '../states/header.state';
import { T } from '@transifex/angular';

const isAppleMobileDevice = ['iPhone', 'iPad', 'iPod'].indexOf(navigator.platform) !== -1;
const isAndroidMobileDevice = /Android/.test(navigator.userAgent);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements AfterViewInit {
  @T('Guess less,\nKnow more', { _key: 'home.feature_x.title' })
  private readonly homeFeature1Title!: string;
  @T('Maximise uptime, reduce delays and increase revenue through the data available and clear action to undertake.', { _key: 'home.feature_x.description' })
  private readonly homeFeature1Description!: string;

  @T('Smarter tools,\nSmarter decisions', { _key: 'home.feature_2.title' })
  private readonly homeFeature2Title!: string;
  @T('Meaningful data and instant communications help to ensure operators are safe and your equipment is in good health.', { _key: 'home.feature_2.description' })
  private readonly homeFeature2Description!: string;

  @T('Effortless meets\nPaperless', { _key: 'home.feature_3.title' })
  private readonly homeFeature3Title!: string;
  @T('JCB LiveLink works with more than just JCB machines. Add your entire fleet through AEMP / ISO telematics standard data feeds.', { _key: 'home.feature_3.description' })
  private readonly homeFeature3Description!: string;

  @T('Mixed fleet', { _key: 'home.feature_4.title' })
  private readonly homeFeature4Title!: string;
  @T('JCB LiveLink works with more than just JCB machines. Add your entire fleet for complete control.', { _key: 'home.feature_4.description' })
  private readonly homeFeature4Description!: string;
  @T('Manage your fleet', { _key: 'home.feature_4.link_text' })
  private readonly homeFeature4LinkText!: string;

  @T('Integrate for efficiency', { _key: 'home.feature_5.title' })
  private readonly homeFeature5Title!: string;
  @T('Connect with existing systems to reduce administration through data integrations available. Store documents in a central location and share equipment data with other users.', { _key: 'home.feature_5.description' })
  private readonly homeFeature5Description!: string;
  @T('Join LiveLink', { _key: 'home.feature_5.link_text' })
  private readonly homeFeature5LinkText!: string;

  readonly websiteUrl = environment.websiteUrl;
  readonly onboardingUrl = environment.onboardingUrl;
  readonly backgroundVideoUrl = this.getSafeUrl(environment.backgroundVideoUrl);
  readonly foregroundVideoUrl = this.getSafeUrl(environment.foregroundVideoUrl);
  readonly appStoreUrl = this.getSafeUrl(environment.appStoreUrl);
  readonly androidStoreUrl = this.getSafeUrl(environment.androidStoreUrl);
  readonly trustedByEnabled = environment.trustedByEnabled;
  readonly showcaseEnabled = environment.showcaseEnabled;
  readonly appStoresEnabled = environment.appStoresEnabled;
  readonly featuresEnabled = environment.featuresEnabled;

  readonly slides: string[] = [
    'assets/images/balfour-beatty.png',
    'assets/images/kier.png',
    'assets/images/ardent.png',
    'assets/images/sunbelt-rentals.png',
  ];

  readonly carouselFirstFeatures: Feature[] = [{
    title: this.homeFeature1Title,
    description: this.homeFeature1Description,
    iconUrl: 'assets/images/uptime-icon.svg',
    imageUrl: 'assets/images/dashboard-image.png',
  }, {
    title: this.homeFeature2Title,
    description: this.homeFeature2Description,
    iconUrl: 'assets/images/smart-icon.svg',
    imageUrl: 'assets/images/dashboard-image-smarter.png',
  }, {
    title: this.homeFeature3Title,
    description: this.homeFeature3Description,
    iconUrl: 'assets/images/paperless-icon.svg',
    imageUrl: 'assets/images/dashboard-image-paperless.png',
  }];

  readonly features: Feature[] = [{
    title: this.homeFeature4Title,
    description: this.homeFeature4Description,
    iconUrl: 'assets/images/fleet-icon.svg',
    imageUrl: 'assets/images/livelink-mixed-fleet-feature.png',
    linkText: this.homeFeature4LinkText,
    linkUrl: this.onboardingUrl,
  }, {
    title: this.homeFeature5Title,
    description: this.homeFeature5Description,
    iconUrl: 'assets/images/integrate-icon.svg',
    imageUrl: 'assets/images/livelink-integrate-feature.png',
    linkText: this.homeFeature5LinkText,
    linkUrl: this.onboardingUrl,
  }];

  playingVideo = false;

  constructor(
    private readonly store: Store,
    private readonly domSanitizer: DomSanitizer,
    private readonly route: ActivatedRoute,
    private readonly scroller: ViewportScroller,
  ) { }

  ngAfterViewInit(): void {
    const fragment = this.route.snapshot.fragment;
    if (fragment) {
      this.scroller.scrollToAnchor(fragment);
    }
  }

  playVideo(): void {
    this.playingVideo = true;
  }

  closeVideo(): void {
    this.playingVideo = false;
  }

  isAppStoreVisible(): boolean {
    return isAppleMobileDevice || !isAndroidMobileDevice;
  }

  isPlayStoreVisible(): boolean {
    return isAndroidMobileDevice || !isAppleMobileDevice;
  }

  getSafeUrl(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  onLoginClick(): void {
    const dropdown = getComputedStyle(document.querySelector('.btn-dropdown'));
    if (dropdown.display === 'none') {
      location.href = this.websiteUrl;
      return;
    }

    this.store.dispatch(new SetLoginTooltipActiveAction(true));
  }

  isEnabled(component: string): boolean {
    return this[component] === 'True' || this[component] === true;
  }
}
