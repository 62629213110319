import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';

import * as hammer from 'hammerjs';
import 'hammer-timejs';

import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { HeaderComponent } from 'src/app/header/header.component';
import { FooterComponent } from 'src/app/footer/footer.component';
import { HomeComponent } from 'src/app/home/home.component';
import { CarouselComponent } from 'src/app/carousel/carousel.component';
import { FeaturesComponent } from 'src/app/features/features.component';
import { TestimonialsComponent } from 'src/app/testimonials/testimonials.component';
import { SlideshowComponent } from 'src/app/slideshow/slideshow.component';
import { NgxsModule } from '@ngxs/store';
import { HeaderState } from 'src/app/states/header.state';
import { environment } from 'src/environments/environment';
import { LatestUpdatesListPageComponent } from 'src/app/pages/latest-updates-list-page/latest-updates-list-page.component';
import { LatestUpdatesDetailPageComponent } from 'src/app/pages/latest-updates-detail-page/latest-updates-detail-page.component';
import { ParseRichtextPipe } from 'src/app/pipes/parse-richtext.pipe';
import { JoinLivelinkComponent } from 'src/app/join-livelink/join-livelink.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TxNativeModule } from '@transifex/angular';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: hammer.DIRECTION_HORIZONTAL },
    pinch: { enable: false },
    rotate: { enable: false },
  };
}

@NgModule({
  imports: [
    BrowserModule,
    TxNativeModule.forRoot(),
    AppRoutingModule,
    NgxsModule.forRoot([HeaderState], { developmentMode: !environment.production }),
    SharedModule,
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    CarouselComponent,
    FeaturesComponent,
    TestimonialsComponent,
    SlideshowComponent,
    LatestUpdatesListPageComponent,
    LatestUpdatesDetailPageComponent,
    ParseRichtextPipe,
    JoinLivelinkComponent,
  ],
  providers: [{
    provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig,
  }],
  bootstrap: [AppComponent],
})
export class AppModule {}
