// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from './environment.base';

const constants = (window as any).constants;

export const environment: IEnvironment = {
  production: false,
  websiteUrl: 'https://app.jcblivelink.com',
  onboardingUrl: 'https://app.jcblivelink.com/onboarding',
  liveLinkClassicUrl: 'https://jcbll.com',
  backgroundVideoUrl: 'https://player.vimeo.com/video/441337568?background=1',
  foregroundVideoUrl: 'https://player.vimeo.com/video/441337568?autoplay=1&byline=0&color=fcb026&controls=true&fun=false',
  appStoreUrl: 'https://apps.apple.com/gb/app/jcb-operator-app/id1449863191',
  androidStoreUrl: 'https://play.google.com/store/apps/details?id=com.jcb.jcboperator',
  trustedByEnabled: false,
  showcaseEnabled: true,
  appStoresEnabled: true,
  featuresEnabled: true,
  testimonialsEnabled: true,
  contentfulSpaceId: constants?.contentfulSpaceId,
  contentfulEnvironment: constants?.contentfulEnvironment,
  contentfulAccessToken: constants?.contentfulAccessToken,
  transifexApiToken: constants?.transifexApiToken,
  defaultLanguage: 'en_GB',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
