import { Injectable } from '@angular/core';
import { ContentfulService } from 'src/app/contentful/contentful.service';
import { LatestUpdateQueryParamsDto } from 'src/app/contentful/dto/latest-update-query-params.dto';
import { map, Observable, of, tap } from 'rxjs';
import { LatestUpdatePostResponseDto } from 'src/app/contentful/dto/latest-update-post.dto';
import { ContentfulQueryParamsDto } from 'src/app/contentful/dto/contentful-query-params.dto';
import { transformAndValidatePipe } from 'src/app/utils/operators';

@Injectable({
  providedIn: 'root'
})
export class LatestUpdatesDetailPageService {

  constructor(
    private readonly contentfulService: ContentfulService,
  ) { }

  query(params?: LatestUpdateQueryParamsDto, relatedArticles?: string): Observable<LatestUpdatePostResponseDto> {
    return this.contentfulService.query(new ContentfulQueryParamsDto({
      limit: params.limit,
      skip: params.skip,
      order: params.order,
      contentType: 'latestUpdatePost',
      select: params.select,
      fieldsUrl: params.url,
      fieldsUrlIn: relatedArticles,
    })).pipe(
      map((response) => ({
        data: response.items.map((item) => item.fields),
      }) as LatestUpdatePostResponseDto),
      transformAndValidatePipe(LatestUpdatePostResponseDto),
    );
  }

  getPost(params?: LatestUpdateQueryParamsDto): Observable<LatestUpdatePostResponseDto> {
    return this.query(params);
  }

  getRelatedPosts(params?: LatestUpdateQueryParamsDto, relatedArticles?: string): Observable<LatestUpdatePostResponseDto> {
    return this.query(params, relatedArticles);
  }
}
