import { Pipe, PipeTransform } from '@angular/core';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

@Pipe({
  name: 'parseRichtext'
})
export class ParseRichtextPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    if (value === undefined || value === null || value.nodeType !== 'document') {
      return `<p class="text-danger">${
        $localize`Unable to retrieve content.`
      }</p>`;
    }

    return documentToHtmlString(value, {
      renderNode: {
        [BLOCKS.HEADING_2]: (node, next) => `<h2 class="rich-text__heading-2">${next(node.content)}</h2>`,
        [BLOCKS.HEADING_3]: (node, next) => `<h3 class="rich-text__heading-3">${next(node.content)}</h3>`,
        [BLOCKS.HEADING_4]: (node, next) => `<h4 class="rich-text__heading-4">${next(node.content)}</h4>`,
        [BLOCKS.HEADING_5]: (node, next) => `<h5 class="rich-text__heading-5">${next(node.content)}</h5>`,
        [BLOCKS.HEADING_6]: (node, next) => `<h6 class="rich-text__heading-6">${next(node.content)}</h6>`,
        [BLOCKS.PARAGRAPH]: (node, next) => `<p class="rich-text__paragraph">${next(node.content).replace("\n", "<br/>")}</p>`,
        [BLOCKS.EMBEDDED_ASSET]: node => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}" class="rich-text__image" style="width: 100%;" />`,
        [BLOCKS.OL_LIST]: (node, next) => `<ol class="rich-text__ordered-list">${next(node.content)}</ol>`,
        [BLOCKS.UL_LIST]: (node, next) => `<ul class="rich-text__unordered-list">${next(node.content)}</ul>`,
        [BLOCKS.LIST_ITEM]: (node, next) => `<li class="rich-text__list-item">${next(node.content).replace(/<[^>]*>/g, '')}</li>`
      },
    });
  }

}
