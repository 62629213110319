import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LatestUpdatesDetailPageService } from 'src/app/pages/latest-updates-detail-page/latest-updates-detail-page.service';
import { LatestUpdateQueryParamsDto } from 'src/app/contentful/dto/latest-update-query-params.dto';
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as AOS from 'aos';
import { LatestUpdatePostResponseDto } from 'src/app/contentful/dto/latest-update-post.dto';
import { T } from '@transifex/angular';

@Component({
  selector: 'app-latest-updates-detail-page',
  templateUrl: './latest-updates-detail-page.component.html',
  styleUrls: ['./latest-updates-detail-page.component.scss'],
})
export class LatestUpdatesDetailPageComponent implements OnInit {
  @T('JCB LiveLink Latest Updates | ', { _key: 'latest_updates.details.meta_title' }) private readonly metaTitle!: string;

  protected slug = new BehaviorSubject<string>('');

  protected post$ = this.slug.pipe(
    switchMap((slug) => {
      // Weird bugs happen if this isn't called
      AOS.refresh();
      return this.latestUpdatesDetailPageService.getPost(new LatestUpdateQueryParamsDto({
        limit: 1,
        skip: 0,
        url: slug,
        select: 'fields.body,fields.title,fields.mainImage,fields.releaseDate,fields.url,fields.tagline,fields.taglineHighlight,fields.relatedArticles,fields.status',
      })).pipe(
        map((response) => response.data[0]),
        tap((post) => {
          this.setMetaTags(post);
        }),
      );
    }),
  );

  protected relatedArticles$ = this.post$.pipe(
    switchMap((post) => {
      if (!post.relatedArticles && !post.relatedArticles?.length) {
        return of([]);
      }

      return this.getRelatedArticles(post.relatedArticles);
    }),
    map((response: LatestUpdatePostResponseDto) => {
      return response.data;
    }),
  );

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly latestUpdatesDetailPageService: LatestUpdatesDetailPageService,
    private titleService: Title,
    private metaTagService: Meta,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.slug.next(params.slug);
    });
  }

  private setMetaTags(post): void {
    this.titleService.setTitle(this.metaTitle + (post.metaTitle || post.title));
    this.metaTagService.addTags([
      { name: 'description', content: post.metaDescription },
    ]);
  }

  private getRelatedArticles(relatedArticles: string[]): Observable<LatestUpdatePostResponseDto> {
    return this.latestUpdatesDetailPageService.getRelatedPosts(new LatestUpdateQueryParamsDto({
      limit: 3,
      skip: 0,
    }), relatedArticles.join(','));
  }
}
