import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LatestUpdatesListPageComponent } from 'src/app/pages/latest-updates-list-page/latest-updates-list-page.component';
import { LatestUpdatesDetailPageComponent } from 'src/app/pages/latest-updates-detail-page/latest-updates-detail-page.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: HomeComponent },
  { path: 'latest-updates', component: LatestUpdatesListPageComponent },
  { path: 'latest-updates/:slug', component: LatestUpdatesDetailPageComponent },
  { path: '', pathMatch: 'prefix', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
