import { IsNumber, IsOptional, IsString } from 'class-validator';
import { DataMember } from 'src/app/utils/data-member';

export class LatestUpdateQueryParamsDto {
  @DataMember('skip', IsNumber())
  skip: number = 0;

  @DataMember('limit', IsNumber())
  limit: number = 6;

  @DataMember('order', IsOptional(), IsString())
  order?: string;

  @DataMember('select', IsOptional(), IsString())
  select?: string;

  @DataMember('url', IsOptional(), IsString())
  url?: string;

  constructor(dto?: LatestUpdateQueryParamsDto) {
    if (dto) {
      this.skip = dto.skip;
      this.limit = dto.limit;
      this.order = dto.order || '-sys.createdAt';
      this.select = dto.select || 'fields.title,fields.url,fields.releaseDate,fields.mainImage,fields.status,sys.createdAt';
      this.url = dto.url;
    }
  }
}
