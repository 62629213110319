import { Component, ElementRef, ViewChild } from '@angular/core';
import { LatestUpdatesListPageService, PostFilterOptions } from 'src/app/pages/latest-updates-list-page/latest-updates-list-page.service';
import { LatestUpdateQueryParamsDto } from 'src/app/contentful/dto/latest-update-query-params.dto';
import { BehaviorSubject, combineLatest, debounceTime, map, of, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LatestUpdatePostDto } from 'src/app/contentful/dto/latest-update-post.dto';

@Component({
  selector: 'app-latest-updates-list-page',
  templateUrl: './latest-updates-list-page.component.html',
  styleUrls: ['./latest-updates-list-page.component.scss'],
})
export class LatestUpdatesListPageComponent {
  @ViewChild('resultsContainerRef') readonly resultsContainerRef: ElementRef<HTMLElement>;
  readonly PostFilterOptions = PostFilterOptions;

  pageHeight = null;
  previousSearchTerm = '';
  previousFilter: PostFilterOptions;
  loadMore = false;
  isLoading = true;
  posts: LatestUpdatePostDto[] = [];
  total = 0;

  searchTerm$ = new BehaviorSubject<string>('');
  filter$ = new BehaviorSubject<PostFilterOptions>(PostFilterOptions.All);
  loadMore$ = new BehaviorSubject<boolean>(false);

  posts$ = combineLatest([
    this.searchTerm$,
    this.filter$,
    this.loadMore$,
  ]).pipe(
    tap(() => {
      this.isLoading = true;
      this.pageHeight = this.resultsContainerRef?.nativeElement.clientHeight;
    }),
    debounceTime(300),
    switchMap(([searchTerm, filter]) => {
      let shouldSkip = false;

      if (this.previousSearchTerm === searchTerm && this.previousFilter === filter && !this.loadMore) {
        return of(this.posts);
      }

      if (this.previousFilter === filter && this.previousSearchTerm === searchTerm) {
        shouldSkip = true;
      }

      return this.listUpdatesListPageService.getPosts(new LatestUpdateQueryParamsDto({
        limit: 6,
        skip: shouldSkip ? this.posts.length : 0,
      }), {
        filter,
        searchTerm,
      }).pipe(
        map((response) => {
          if (this.previousFilter !== filter || this.previousSearchTerm !== searchTerm) {
            this.posts = response.data;
          } else {
            this.posts = [...this.posts, ...response.data];
          }

          this.previousFilter = filter;
          this.previousSearchTerm = searchTerm;
          this.loadMore = false;
          this.total = response.total;
          return this.posts;
        }),
      );
    }),
    tap(() => this.isLoading = false),
  );

  protected setSearchTerm(event: Event) {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.searchTerm$.next(searchTerm);
  }

  protected loadMorePosts() {
    this.loadMore = true;
    this.loadMore$.next(true);
  }

  constructor(
    private readonly listUpdatesListPageService: LatestUpdatesListPageService,
  ) {}
}
