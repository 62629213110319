<div class="jumbotron mb-0 bg-transparent hero">
  <div class="background-video-container">
    <ng-container *ngIf="!playingVideo">
      <iframe class="background-video" frameborder="0" [src]="backgroundVideoUrl"></iframe>
    </ng-container>
  </div>

  <div class="container text-center text-white">
    <div class="display-2">
      <T str="Complete control<br>over your entire fleet" key="home.hero.title" [sanitize]="true"></T>
    </div>

    <div class="narrow text-center mx-auto mt-3 mt-lg-4">
      <p class="lead m-0">
        <T
          str="JCB LiveLink provides everything you need to keep track of your fleet all in one place. View multiple brands of equipment, store documentation, visibility of machine checks and sharing machine data with others to name just a few of the features. Increase Uptime, Reduce Admin, Make Better Decisions."
          key="home.hero.subtitle"
          [sanitize]="true"
        ></T>
      </p>

      <p class="lead mt-4 text-underline">
        <a [href]="onboardingUrl" class="text-white"><T str="Get Started" key="home.hero.get_started"></T></a>
      </p>
    </div>

    <div class="d-md-flex align-items-center justify-content-center mt-5">
      <a [href]="onboardingUrl" class="btn btn-primary btn-lg mx-3 mb-3">
        <T str="Register" key="home.hero.register"></T>
      </a>
      <button (click)="onLoginClick()" class="btn btn-outline-light btn-lg mx-3 mb-3 btn-dropdown-area">
        {{ 'Log in' | translate: { _key: 'text.log_in', _tags: 'common' } }}
      </button>
    </div>

    <div class="text-center mt-5">
      <button class="play-video-link mt-5" (click)="playVideo()">
        <T str="Play video" key="home.hero.play_video"></T>
      </button>
    </div>
  </div>
</div>

<div class="play-video-overlay" *ngIf="playingVideo">
  <div class="play-video-content">
    <div class="play-video-close" (click)="closeVideo()">X</div>

    <div class="play-video-container">
      <iframe
        class="play-video-video"
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        [src]="foregroundVideoUrl"
      ></iframe>
    </div>
  </div>
</div>

<div class="section trusted-by" *ngIf="isEnabled('trustedByEnabled')">
  <div class="container text-center">
    <div class="section-title text-uppercase">
      <T str="Trusted by industry leaders" key="home.trusted_by.trusted_by_industry_leaders"></T>
    </div>
    <app-slideshow [slides]="slides" [height]="38"></app-slideshow>
  </div>
</div>

<div class="section d-none d-lg-block" *ngIf="isEnabled('showcaseEnabled')" [class.mt-5]="!isEnabled('trustedByEnabled')">
  <app-carousel [features]="carouselFirstFeatures" [accent]="true"></app-carousel>
</div>
<div class="section mobile-carousel-features bg-light d-lg-none">
  <app-features [features]="carouselFirstFeatures"></app-features>
</div>

<div class="section bg-dark" *ngIf="isEnabled('appStoresEnabled')">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-6 py-5">
        <div id="jcb-operator-app-title" class="display-4 mb-4 text-primary" data-aos="fade-right" data-aos-once="true">
          <T str="The JCB Operator App.<br>Work smarter." key="home.app_stores.operator_app_title" [sanitize]="true"></T>
        </div>
        <p class="lead text-white" data-aos="fade-right" data-aos-anchor="#jcb-operator-app-title" data-aos-once="true" data-aos-delay="100">
          <T
            str="Integrate LiveLink with JCB Operator mobile app and essential data from all your operators' daily equipment checks feeds instantly into your personalised dashboard."
            key="home.app_stores.operator_app_subtitle"
            [sanitize]="true"
          ></T>
        </p>
        <a class="download-in-app-store mt-3" [href]="appStoreUrl" *ngIf="isAppStoreVisible()">
          <img src="assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg">
        </a>
        <a class="download-in-play-store mt-3" [href]="androidStoreUrl" *ngIf="isPlayStoreVisible()">
          <img src="assets/images/google-play-badge.png">
        </a>
      </div>
      <div class="col-12 col-lg-6">
        <div class="app-mockup d-lg-none" data-aos="fade-up" data-aos-once="true"></div>
        <div class="app-mockup d-none d-lg-block" data-aos="fade-left" data-aos-anchor="#jcb-operator-app-title" data-aos-once="true" data-aos-delay="200"></div>
      </div>
    </div>
  </div>
</div>

<div class="section py-5 bg-white" id="features" *ngIf="isEnabled('featuresEnabled')">
  <div class="mt-lg-5">
    <app-features [features]="features"></app-features>
  </div>
</div>

<app-testimonials></app-testimonials>
<app-join-livelink></app-join-livelink>
